export const Fonts = () => (
    <>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
            href="https://fonts.googleapis.com/css2?family=Crete+Round&display=swap"
            rel="stylesheet"
        />
    </>
);
